import { css } from '@emotion/react'
import React, { useState, useEffect, Fragment, useContext } from 'react'
// import styled from '@emotion/styled'
import {
    Grid,
    Typography,
    Box
} from '@material-ui/core';
import {
    CardElevation,
    ButtonDegrade,
    Oferta,
    // CardProgres
} from '@findep/mf-landings-core';
import CarouselImg from '../img/CarouselImg.compilable'
import { navigate } from "../../helpers/queryNavigate"
import FelicidadesApp from '../elements/FelicidadesApp.compilable';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import { CataloguesService } from '../../services/LandingV4'
import formatNumber from '../../helpers/formatNumber'
import NameCompany from '../../helpers/NameCompany'
import { getPeriodPlural } from '../../helpers/period'
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext';
import getTenant from '../../helpers/getTenant';

// import {
//     getTaskIcon,
//     getTaskStatusIcon,
//     getTaskUrl,
//     getTaskActionText,
//     getSpecialStatusCase,
//     getOrderArray,

// } from '../helpers/tubeTask'

const Felicidades = ({ pageContext, companyName }) => {

    const { setIsDynamic, setTenantFooter } = useContext(GlobalStateFooterContext);    

    const [flujo, setFlujo] = useState({});
    const [survey, setSurvey] = useState('');
    const [congratulations, setCongratulations] = useState('')
    const [nameCompany, setNameCompany] = useState(NameCompany(companyName));
    const [fecha, setFecha] = useState('');

    useEffect(() => {
        const getTenantHelper = async () => {
            let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            let id = flujo?.creditApplicationId
            //GET TENANT        
            const tenantValue = await getTenant(id)
            setIsDynamic(true)
            setTenantFooter(tenantValue)
        }

        getTenantHelper()
    }, [])

    useEffect(() => {
        
        setIsDynamic(true)
        setTenantFooter(pageContext.tenant)
        let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let localSurvey = sessionStorage.getItem('survey') || {}
        let congratulaionMessage

        setFlujo(localFlujo)

        async function getdata() {

            const catalogos = new CataloguesService()
            const catData = await catalogos.getSurveys(localFlujo.creditApplicationId)

            if (catData.status === 200) {
                localSurvey = 'true'
                setSurvey(localSurvey)
            }

            const data = await catalogos.getCongratulations(localFlujo.creditApplicationId)
            congratulaionMessage = data.data


            setCongratulations(congratulaionMessage)
            const { forceRedirect } = data.data
            if (forceRedirect !== null && forceRedirect !== undefined) {
                navigate(forceRedirect)
            }
        }
        try {
            getdata()
        } catch (e) {
            console.error(e)
        }

        var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
        let f = new Date(localFlujo?.preOferta?.oferta?.nextPayment)
        let arrFecha = `${f.getDate()}-${meses[f.getMonth()]}-${f.getFullYear()}`
        setFecha(arrFecha)

    }, [])

    const TextHeader = css`
    color: primary;
    text-align: center;
    font-weight: bold;
    font-size: 3em;
  `
    const textSecondHeader = css`
    text-align: center;
    font-size: 2em;
    `

    const textMessage = css`
        font-size: 1rem;
    `
    const textMessageNote = css`
        font-size: 1rem;
        font-weight: bold;
        padding-top: 300px;
    `

    const styleTextEncuesta = css`
    padding: 1rem;
    color: rgba(0, 0, 0, 0.87);
`

    const rootPage = css`
    padding-left: 1rem;
    padding-right: 1rem;
`

    const props = [
        { icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(congratulations?.amount || 0)}` },
        { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(congratulations?.estimatedPayment || 0)}` },
        { icono: ArrowForwardIcon, titulo: 'Plazo:', texto: `${congratulations?.terms || 0 } ${getPeriodPlural(congratulations?.period || '')}`},
        { icono: ArrowForwardIcon, titulo: 'Primer pago:', texto: flujo ? flujo.preOferta ? `${fecha}` : '' : '' },
    ]

    const propsSucursal = [
        { icono: ArrowForwardIcon, titulo: 'Sucursal: ', texto: `${congratulations?.branch?.name}  ${congratulations?.branch?.city ? congratulations?.branch?.city : ''} Col. ${congratulations?.branch?.neighborhood} ${congratulations?.branch?.address} ${congratulations?.branch?.postalCode} ${congratulations?.branch?.state}`, sizeFontLi: '14px' },
    ]
    return (
        <div>
            <Grid container direction="column" justify="center" alignItems="center" css={rootPage}>
                <br />
                <br />
                <Typography color='primary'>
                    <p css={TextHeader}>¡Felicidades!</p>
                </Typography>
                <br />
                {/* <Typography align="center" color='secondary' variant="h5" css={textSecondHeader}>
                    Tu crédito fue aprobado
                    </Typography>
                <Typography css={styleTextEncuesta}>
                    Con los datos que nos proporcionaste, {nameCompany.legalName}, tiene para ti un crédito con los siguientes términos:
                    </Typography> */}
                <Oferta datos={props} colorOne="#FF9F01" colorTwo="#FA4616" />
                <br />
                <Typography css={styleTextEncuesta}>
                    <p css={textMessage}>{congratulations.customerMessage}.</p>
                    {/* <br /><br /> */}
                    {
                        congratulations.branch ? <Oferta datos={propsSucursal} colorOne="#FF9F01" colorTwo="#FA4616" /> : null
                    }
                </Typography>
                {congratulations !== '' && <Typography> 
                    <br />
                        
                            <ReportProblemIcon color="primary" />
                            <span item css={textMessageNote}> NOTA: Información sujeta a cambios, consulta tu tabla de amortización en tu contrato donde podrás ver el monto de la cuota y la fecha de pago próxima.</span>
                    <br />
                    <br />
                </Typography>}
                {/* {tubito.tasks.length === 0 ? 'cargando...': 
                        <CardElevation>
                            {
                                tubito.tasks.map((task, index) => {
                                    const status = task.status
                                    return (
                                        <CardProgres
                                            key={task.kind}
                                            firstItem={index === 0}
                                            lastItem={index + 1 === tubito.tasks.length}
                                            icon={status?.toUpperCase() === 'NEW' ? getTaskIcon(task.kind) : getTaskStatusIcon(status)}
                                            title={task.title}
                                            body={<>{task.description}</>}
                                            textAction={getTaskActionText(task.kind)}
                                            status={status?.toLowerCase()}
                                            isExpanded
                                            // onChange={() => navigate(`${getTaskUrl(task.kind)}?creditApplicationId=${creditApplicationId}`)}
                                        />
                                    )
                                }
                                )
                            }
                        </CardElevation>
                } */}
                <br />
                <br />
                <br />
                <CardElevation>
                    <Typography>
                        {/*<p style={{fontSize: '1rem'}}>Descarga nuestra APP sin costo y hazte la vida más fácil</p>*/}
                        <p style={{fontSize: '1rem'}}>¡Ahorra tiempo! Maneja tu cuenta en donde te encuentres. ¡Descarga nuestra aplicación hoy!</p>
                    </Typography>

                    <FelicidadesApp company={companyName} />

                </CardElevation>
                        <br />

                        <Fragment>
                            <Typography css={styleTextEncuesta} align="center">
                                ¿Nos ayudarías a contestar una breve encuesta para mejorar nuestro servicio?
                            </Typography>
                            <br />
                            <Box display="flex" justifyContent="flex-end">
                                <ButtonDegrade
                                    onClick={
                                        () => navigate(`/encuesta/`)
                                    }
                                    textButton="CONTESTAR ENCUESTA"
                                />
                            </Box>
                        </Fragment>
                 
                <CarouselImg filename="1.svg" company={companyName} />
                <br />
            </Grid>
        </div>

    )
}

export default Felicidades